<template>
  <div>
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <b-row>
      <b-col>
        <b-form v-on:submit.prevent="submit">
          <b-row>
            <b-col>
              <b-form-file v-model="file" ref="file-input" class="mb-2" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" @change="checkExtension"></b-form-file>
              <p class="mt-0">Selected file: <b>{{ file ? file.name : '' }}</b></p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button type="submit" variant="info" class="m-1">Update Bulk Voucher</b-button>
              <b-button type="button" @click="file = ''" variant="danger" class="m-1">Reset</b-button>
              <b-button type="button" @click="downloadTemplate" variant="success" class="m-1">Download Template</b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
    <b-row class="mb-3 mt-3">
      <b-col>
        <h5>Peringatan !</h5>
        <ul style="font-size: 10pt; font-weight: bold">
          <li>Sebelum melakukan prosess update harap cek koneksi untuk meminimalisir koneksi yang terputus.</li>
          <li>Pengisian format data harus sama persis seperti template yang disediakan.</li>
          <li>Proses Import Voucher Akan berhenti jika ada baris yang bermasalah. Namun data sebelum baris yang bermasalah akan berhasil diimport.</li>
          <li>Beberapa Colom yang dapat diubah.
            <ul>
              <li>Code</li>
              <li>New Code</li>
              <li>Action</li>
            </ul>
          </li>
        </ul>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm>
          <div>
            <button class="btn btn-success" type="button" @click="exportTable">Export</button>
          </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm>
        <div>
          <b-form-group
            class="mb-3"
            label="Search :"
            label-for="search"
            description="Searchable : Code"
          >
              <b-input-group
              >
                <b-form-input
                  id="search"
                  type="search"
                  v-model="filterText"
                  placeholder="Code ..."
                  @keypress="doFilter"
                  @keyup.delete="doFilter"
                >
                </b-form-input>
                <b-input-group-append
                >
                  <b-button
                    variant="secondary" @click="resetFilter"
                    type="button"
                  >
                    Reset
                  </b-button>
                </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
      <b-col sm>
        <div>
          <b-form-group
            class="mb-3"
            label="Date :"
            label-for="daterange"
          >
            <b-input-group
            >
              <b-input-group-prepend is-text>
                <i class="fa fa-calendar"></i>
              </b-input-group-prepend>
              <range-picker id="daterange" :start="startDate" :end="endDate" @picker="doDateFilter"></range-picker>
              <b-input-group-append>
                <b-button type="button" variant="secondary" @click="resetDateFilter">Reset</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <vuetable ref="vuetable"
      @vuetable:load-error="handleLoadError"
      :api-url="apiUrl"
      :http-options="HttpOptions"
      :fields="fields"
      pagination-path=""
      :muti-sort="true"
      :sort-order="sortOrder"
      :append-params="moreParams"
      @vuetable:pagination-data="onPaginationData">

      <template slot="prefix-slot" slot-scope="prop">
        <span>{{ prop.rowData.marketplace.merchant_prefix }}</span>
      </template>
      <template slot="code-slot" slot-scope="prop">
        <b-badge pill variant="dark" class="badge--code">{{ prop.rowData.code }}</b-badge>
      </template>
    </vuetable>
    <div class="vuetable-pagination ui basic segment grid">
      <vuetable-pagination-info ref="paginationInfo"
      ></vuetable-pagination-info>

      <vuetable-pagination ref="pagination"
        @vuetable-pagination:change-page="onChangePage"
      ></vuetable-pagination>
    </div>
  </div>
</template>

<script>
  import accounting from 'accounting'
  import Vue from 'vue'
  import Vuetable from 'vuetable-2/src/components/Vuetable'
  import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
  import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
  import moment from 'moment/src/moment'
  let FullDateNow = moment().format('YYYY-MM-DD hh:mm:ss')
  let startDate = '';
  let endDate = '';
  Vue.use(Vuetable)

  export default {
    components: {
      Vuetable,
      VuetablePagination,
      VuetablePaginationInfo,
    },
    prop: {
      rowData: {
        type: Object,
        required: true
      },
      rowIndex: {
        type: Number
      }
    },
    data() {
      return {
        startDate: '',
        endDate: '',
        file: '',
        isLoading: false,
        filterText: '',
        dateDetail: {
          start_from: '',
          end_from: ''
        },
        timeNow: FullDateNow,
        errors: {
          code: '',
          message: '',
          status: ''
        },
        apiUrl: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `partnership-detail?hashedId=${this.$route.params.id}`,
        HttpOptions: {
          headers: {
            'Accept' : 'application/json',
            'Authorization' : 'Bearer ' + localStorage.getItem('access_token')
          }
        },
        sortOrder: [
          {
            field: 'created_at',
            sortField: 'created_at',
            direction: 'desc'
          }
        ],
        moreParams: {},
        fields: [
          {
            name: 'prefix-slot',
            title: 'Promotion Prefix Merchant'
          },
          {
            name: 'code-slot',
            sortField: 'code',
            title: 'Code'
          },
          {
            name: 'shared',
            titleClass: 'center aligned',
            dataClass: 'center aligned',
            title: 'Shared',
            formatter: this.activeLabel
          }
        ]
      }
    },
    methods: {
      async downloadTemplate() {
        window.location.href = 'https://content.treasury.id/exports/example.xlsx'

      },
      checkExtension (sender) {
        var validExts = new Array(".xlsx", ".xls");
        var fileExt = sender.target.value;
        fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
        if (validExts.indexOf(fileExt) < 0) {
          this.$swal.fire(
            'Failed!',
            "Invalid file selected, valid files are of " + validExts.toString() + " types.",
            'error'
          ).then(() => {
            this.file = ''
            return false;
          })
        }
        else return true;
      },
      submit() {
        if (this.file === '') {
          return this.$swal.fire(
            'Failed',
            'Harap masukan file untuk diimport.',
            'error'
          )
        }
        const formData = new FormData
        formData.append('hashed_id', this.$route.params.id)
        formData.append('file', this.file, this.file.name)
          this.$swal.fire({
            title: 'Melakukan Proses Import',
            text: 'Mohon untuk tidak memuat ulang halaman ini!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Kembali'
          }).then((result) => {
            this.isLoading = true
            if (result.value) {
              this.$http.post(`partnership-import`, formData)
              .then(() => {
                this.isLoading  = false
                this.$swal.fire(
                  'Updated!',
                  'Voucher Berhasil diimport',
                  'success'
                ).then(() => {
                  this.$toasted.success('Voucher successfully imported!')
                  location.reload()
                })
              }).catch((error) => {
                if (error.response) {
                  this.isLoading = false
                  if(error.response.data.meta.code == 400){
                    this.$swal.fire(
                      'Failed!',
                      error.response.data.meta.message,
                      'error'
                    )
                  }else if(error.response.data.meta.code == 422){
                    this.$swal.fire(
                      'Failed!',
                      error.response.data.meta.message,
                      'error'
                    )
                  }
                }
              })
            } else if (result.dismiss === this.$swal.DismissReason.cancel) {
              this.isLoading = false
              this.$swal.fire(
                'Dibatalkan',
                'Proses Import Berhasil dibatalkan!',
                'error'
              )
            }
          })
      },
      doFilter () {
        this.$events.$emit('filter-set', this.filterText)
      },
      resetFilter () {
        this.filterText = ''  // clear the text in text input
        this.$events.$emit('filter-reset')
      },
      exportTable() {
        this.isLoading = true
        if (this.startDate != '' && this.endDate != '') {
          startDate = this.startDate;
          endDate = this.endDate;
        }else{
          endDate = this.$setDate.format('YYYY-MM-DD');
          startDate = this.$setDate.subtract(6, 'days').format('YYYY-MM-DD');
        }

        this.$http.get(`voucher-marketplace/` + this.$route.params.id + '/' + startDate + `/` + endDate)
        .then((result) => {
          this.isLoading = false
          const  exportPath = result.data.meta.data;
          window.location.href = exportPath;
        }).catch((error) => {
          if (error.response) {
            this.isLoading = false
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
          }
        })
      },
      exportMultiple() {
        this.isLoading = true
        if (this.startDate != '' && this.endDate != '') {
          startDate = this.startDate;
          endDate = this.endDate;
        }else{
          endDate = this.$setDate.format('YYYY-MM-DD');
          startDate = this.$setDate.subtract(6, 'days').format('YYYY-MM-DD');
        }

        this.$http.get(`voucher-serveral/` + startDate + `/` + endDate)
        .then((result) => {
          this.isLoading = false
          const  exportPath = result.data.meta.data;
          window.location.href = exportPath;
        }).catch((error) => {
          if (error.response) {
            this.isLoading = false
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
          }
        })
      },
      handleLoadError(error) {
        this.errors.code = error.response.data.meta.code;
        this.errors.message = error.response.data.meta.message;
        this.errors.status = error.response.data.meta.code;
        if (this.errors.code == 401) {
          if (localStorage.getItem('access_token') != null) {
            localStorage.removeItem('access_token');
            this.$swal.fire(
              'Your session expired!',
              'Your session has expired. Please login again to access this page!',
              'error'
            ).then(() =>{
              this.$router.push("/login")
            })
          }
        }else if(this.errors.code == 403) {
          this.$router.push("/403")
        }else if(this.errors.code == 500) {
          this.$router.push("/500")
        }
      },
      activeLabel (value) {
        if (value == true) {
          return '<span class="ui green label"><i class="fa fa-check"></i></span>'
        }else{
          return '<span class="ui red label"><i class="fa fa-times"></i></span>'
        }
      },
      boughtLabel (value) {
        if (value == true) {
          return '<span class="ui green label"><i class="fa fa-check"></i></span>'
        }else{
          return '<span class="ui red label"><i class="fa fa-times"></i></span>'
        }
      },
      formatNumber (value) {
        return value > 100  ? "Rp." + accounting.formatNumber(value, 2) : value + " Gram"
      },
      onChangePage (page) {
        this.$refs.vuetable.changePage(page)
      },
      onPaginationData (paginationData) {
        this.$refs.pagination.setPaginationData(paginationData)
        this.$refs.paginationInfo.setPaginationData(paginationData)
      },
      onAction (action, data) {
        if (action == 'detail-item') {
          this.$router.push({name: 'Detail Voucher', params: { id: data.id}})
        }else if(action == 'download-item'){
          this.$router.push({name: 'Qrcode', params: { id: data.id}})
        }else{
          this.$swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this Voucher!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
          }).then((result) => {
            if (result.value) {

                this.$http.delete(`delete-voucher/` + data.id)
                .then(() => {
                  this.$swal.fire(
                    'Deleted!',
                    'Voucher has been deleted.',
                    'success'
                  ).then(() => {
                    location.reload();
                  })
                }).catch((error) => {
                  if (error.response) {
                    this.errors.code = error.response.status;
                    this.errors.message = error.response.data.meta.message;
                    this.errors.status = error.response.data.meta.code;
                  }
                })
            } else if (result.dismiss === this.$swal.DismissReason.cancel) {
              this.$swal.fire(
                'Cancelled',
                'Voucher was successfully canceled for deletion!',
                'error'
              )
            }
          })
        }
      },
      onFilterSet (filterText) {
          this.moreParams = {
              'filter': filterText
          }
          Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      resetDateFilter () {
        this.startDate = ''
        this.endDate = ''
        this.$events.$emit('date-filter-reset')
      },
      doDateFilter(value){
        this.startDate = value.startDate;
        this.endDate = value.endDate;
        this.$events.fire('date-set', [this.startDate, this.endDate])
      },
      onDateSet()  {
        this.start_from = this.startDate;
        this.end_to = this.endDate;
        this.moreParams = {
          'start_from': this.start_from,
          'end_to': this.end_to
        }
        Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      onFilterReset () {
          this.moreParams = {}
          Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      onDateFilterReset () {
        this.moreParams = {}
        Vue.nextTick( () => this.$refs.vuetable.refresh())
      }
    },
    mounted() {
      this.$events.$on('filter-set', eventData => this.onFilterSet(eventData))
      this.$events.$on('date-set', eventData => this.onDateSet(eventData))
      this.$events.$on('filter-reset', () => this.onFilterReset())
      this.$events.$on('date-filter-reset', () => this.onDateFilterReset())
    },
  }
</script>
<style>

.badge--code {
  font-size: 10pt !important;
}
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
